import request from '@/api/axios-request'

const apiCashtag = {

  create(name, cashtag, mobile, email, sites) {
    return request({
      url: '/ad/cashtags/create',
      method: 'post',
      data: {
        name,
        cashtag,
        mobile,
        email,
        sites
      }
    })
  },

  update(id, name, cashtag, mobile, email, sites, status) {
    return request({
      url: '/ad/cashtags/update',
      method: 'post',
      data: {
        id,
        name,
        cashtag,
        mobile,
        email,
        sites,
        status
      }
    })
  },

  updateStatus(id, status) {
    return request({
      url: '/ad/cashtags/updateStatus',
      method: 'post',
      data: {
        id,
        status
      }
    })
  },

  delete(id) {
    return request({
      url: '/ad/cashtags/delete',
      method: 'post',
      data: {
        id
      }
    })
  },

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/cashtags/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  },

  get(id) {
    return request({
      url: '/ad/cashtags/get',
      method: 'post',
      data: {
        id
      }
    })
  }

}

export default apiCashtag